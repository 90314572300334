import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import USERLIST from '../_mock/gmsts';

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';

// components
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';

import { useNavigate } from "react-router-dom";

import { useEffect, useState, useContext, useCallback, useRef } from "react";

import { SocketContext } from "../main/mysocket";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  // { id: 'name', label: 'Номер', alignRight: false },
  { id: 'email', label: 'Пользователь', alignRight: false },
  { id: 'status', label: 'Статус', alignRight: false },
  { id: 'balance', label: 'Баланс', alignRight: false },
  { id: 'date', label: 'Дата создания', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------

export default function DashboardUsersPage() {

  const _context = useContext(SocketContext);
  const socket = _context._socket;

  const navigate = useNavigate();

  const [toys_info, set_toys_info] = useState([]);
  const reftChargeCoordinates = useRef(null);
  reftChargeCoordinates.current = toys_info;

  const [cur_email, set_cur_email] = useState("");
  const refcur_cpid = useRef(null);
  refcur_cpid.current = cur_email;

  const theme = useTheme();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event, _email) => {

    console.log("handleOpenMenu ", _email);
    set_cur_email(_email)
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleSendCommand = (cmd) => {
    console.log("handleSendCommand ", cmd, refcur_cpid.current);
    setOpen(null);
    socket.emit(cmd, { email: refcur_cpid.current });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = toys_info.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - toys_info.length) : 0;

  const filteredUsers = applySortFilter(toys_info, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handle_front_check_users_res = useCallback((msg) => {
    if (msg) {
      // console.log("msg handle_front_check_trs_res", msg);
      let _t_trs_info = [];
      msg.map((tr_info) => {
        _t_trs_info.push({
          id: tr_info.email,
          myName: tr_info.email,
          myStatus: tr_info.status,
          myBalance: tr_info.balance.toFixed(2),
          myDate: tr_info.createdAt.slice(0, 19).replace('T', ' / '),
        });
      });

      if (
        JSON.stringify(reftChargeCoordinates.current) ===
        JSON.stringify(_t_trs_info)
      ) {
        console.log("tChargeCoordinates) === JSON.stringify(msg");
      } else {
        set_toys_info(_t_trs_info);
      }
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    socket.removeAllListeners();
    clearInterval(_context._auth_timer);
    clearInterval(_context._timer);

    socket.on("front_check_users_res", handle_front_check_users_res);

    let _device_email = localStorage.getItem('device_email')
    let _deviceId = localStorage.getItem("device_id");

    clearInterval(_context._timer);
    _context._timer = setInterval(function () {
      socket.emit("front_check_users_req", { email: _device_email, device_id: _deviceId });
    }, 1000);

    // return () => {};
  }, [socket]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {/* <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={toys_info.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {toys_info.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, myName, myStatus, myBalance, myDate } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        {/* <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {_mynumber}
                            </Typography>
                          </Stack>
                        </TableCell> */}

                        <TableCell align="left">
                          <Label
                            color={'primary'}
                          >
                            {myName}
                          </Label>
                        </TableCell>

                        {/* <TableCell align="left">{myStatus}</TableCell> */}

                        <TableCell align="left">
                          <Label
                            color={
                              (myStatus === "NotAccepted" && "error") ||
                              "success"
                            }
                          >
                            {sentenceCase(myStatus)}
                          </Label>
                        </TableCell>

                        <TableCell align="left">{myBalance}</TableCell>

                        <TableCell align="left">{myDate}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => handleOpenMenu(event, myName)}
                          >
                            <Iconify icon={"eva:more-vertical-fill"} />
                          </IconButton>
                        </TableCell>

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={toys_info.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleSendCommand("front_change_user_status_req")}>
          <Iconify
            icon={'eva:edit-fill'} 
            sx={{ mr: 2 }} 
          />
          Ред. статус
        </MenuItem>

        {/* <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Rst.Системы
        </MenuItem>

        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Запуск игры
        </MenuItem> */}

      </Popover>
    </>
  );
}
