import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";

import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";

import { useState, forwardRef, ReactElement, Ref } from "react";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import FileUpload from "react-material-file-upload";

import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";

import USERLIST from "../_mock/updates";

import { filter } from "lodash";
import { sentenceCase } from "change-case";

// components
import Label from "../components/label";
import Scrollbar from "../components/scrollbar";

import { SocketContext } from "../../src/main/mysocket";
import { useEffect, useContext, useCallback, useRef } from "react";

import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "cpid", label: "Id точки", alignRight: false },
  { id: "name", label: "Имя точки", alignRight: false },
  { id: "c_upt", label: "Тек. прошивка", alignRight: false },
  { id: "upload_upt", label: "", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DashboardAppUpdatesCpsPage() {
  const _context = useContext(SocketContext);
  const socket = _context._socket;

  const navigate = useNavigate();

  const [cps_info, set_cps_info] = useState([]);
  const reftChargeCoordinates = useRef(null);
  reftChargeCoordinates.current = cps_info;

  const [open_upt_dialog, set_open_upt_dialog] = useState(false);

  const [open_choose_upt_dialog, set_open_choose_upt_dialog] = useState(false);
  const [title_cpid_upt_dialog, set_title_cpid_upt_dialog] = useState("");
  const [title_cpname_upt_dialog, set_title_cpname_upt_dialog] = useState("");

  const [upt_names, set_upt_names] = useState([]);
  const reftUptNames = useRef(null);
  reftUptNames.current = upt_names;

  const [files, setFiles] = useState([]);

  const handleClickOpen_upt_dialog = () => {
    set_open_upt_dialog(true);
  };

  const handleClose_upt_dialog = () => {

    console.log('files ', files)

    files.forEach((_file) => {
      socket.emit("front_upload_files_upt", {'bytes': _file, 'name': _file.name}, (status) => {
        console.log(status);
      });
    });

    setFiles([]);
    set_open_upt_dialog(false);
  };

  const handleClose_choose_send_upt_dialog = (upt_file_name) => {

    // console.log({ cpid: title_cpid_upt_dialog, uptfilename:  upt_file_name})
    socket.emit("front_set_uptfile_by_cpid", { cpid: title_cpid_upt_dialog, uptfilename:  upt_file_name});

    set_open_choose_upt_dialog(false);
  };

  const handleClickOpen_choose_upt_dialog = (c_cpid, cpname) => {
    set_title_cpname_upt_dialog(cpname);
    set_title_cpid_upt_dialog(c_cpid);
    set_open_choose_upt_dialog(true);
  };
  const handleClose_choose_upt_dialog = () => {
    set_open_choose_upt_dialog(false);
  };

  const theme = useTheme();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = cps_info.map((n) => n.cpid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - cps_info.length) : 0;

  const filteredUsers = applySortFilter(
    cps_info,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;

  const handle_front_get_all_names_updates_res = useCallback((msg) => {
    if (msg) {
      console.log("msg handle_front_get_all_names_updates_res", msg);

      if (
        JSON.stringify(reftUptNames.current) ===
        JSON.stringify(msg)
      ) {
        console.log("reftUptNames) === JSON.stringify(msg");
      } else {
        set_upt_names(msg);
      }

      
    }
  }, []);

  const handle_front_check_cps_res = useCallback((msg) => {

    if (msg) {
      console.log("msg handle_front_check_cps_res", msg);

    //   let _t_user_w_cps = [];
    //   msg.map((user_info) => {
    //     let _t_cps_info = [];
    //     user_info.cps.map((cp_info) => {
    //       _t_cps_info.push({
    //         id: cp_info.cp_id,
    //         myName: cp_info.name,
    //         myIsonline: cp_info.isOnline ? "online" : "offline",
    //         myShortId: cp_info.myShortId,
    //         myError: cp_info.error_code,
    //         myTmkt: cp_info.tmkt.join(" / "),
    //         myYmkt: cp_info.ymkt.join(" / "),
    //         myDYmkt: cp_info.yymkt.join(" / "),
    //       });
    //     });

    //     _t_user_w_cps.push({
    //       email: user_info.email,
    //       cps: _t_cps_info,
    //     });
    //   });

      if (
        JSON.stringify(reftChargeCoordinates.current) ===
        JSON.stringify(msg)
      ) {
        console.log("tChargeCoordinates) === JSON.stringify(msg");
      } else {
        set_cps_info(msg);
      }
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {

    socket.removeAllListeners();
    clearInterval(_context._auth_timer);
    clearInterval(_context._timer);

    //   socket.on("front_get_all_updates_RES", handle_front_get_all_updates_res);

    //   socket.emit("front_get_all_updates_REQ", {});

    //   _context._timer = setInterval(function () {
    //     socket.emit("front_get_all_updates_REQ", {});
    //   }, 5000);
    // }, [socket]);

    socket.on("front_get_all_updates_RES", handle_front_check_cps_res);
    socket.on("front_get_all_names_updates_RES", handle_front_get_all_names_updates_res);

    socket.emit("front_initpage_cps_req", {});

    clearInterval(_context._timer);
    _context._timer = setInterval(function () {
      socket.emit("front_get_all_updates_REQ", {});
      socket.emit("front_get_all_names_updates_REQ", {});
    }, 1000);

    // return () => {};
  }, [socket]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="right"
          mb={5}
        >
          {/* <Typography variant="h4" gutterBottom>
            User
          </Typography>*/}
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              console.log("open_upt_dialog ", open_upt_dialog);
              handleClickOpen_upt_dialog();
            }}
          >
            Загрузить прошивку
          </Button>
        </Stack>

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={cps_info.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        cpid,
                        name,
                        curupt
                      } = row;
                      const selectedUser = selected.indexOf(cpid) !== -1;

                      return (
                        <TableRow
                          hover
                          key={cpid}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) => handleClick(event, cpid)}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {cpid}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{name}</TableCell>

                          <TableCell align="left">{curupt}</TableCell>

                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              onClick={() => {
                                handleClickOpen_choose_upt_dialog(cpid, name);
                              }}
                            >
                              Выбрать
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cps_info.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Dialog
          fullScreen
          open={open_upt_dialog}
          onClose={handleClose_upt_dialog}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose_upt_dialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Загрузить прошивку
              </Typography>
              <Button
                autoFocus
                color="inherit"
                onClick={handleClose_upt_dialog}
              >
                Готово
              </Button>
            </Toolbar>
          </AppBar>
          <FileUpload value={files} onChange={setFiles} />
        </Dialog>

        <Dialog
          fullScreen
          open={open_choose_upt_dialog}
          onClose={handleClose_choose_upt_dialog}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose_choose_upt_dialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Выбрать прошивку для {title_cpid_upt_dialog +' | '+title_cpname_upt_dialog}
              </Typography>
            </Toolbar>
          </AppBar>

          <List>
            {upt_names.map((upt) => (
              <ListItem button onClick={()=>handleClose_choose_send_upt_dialog(upt)}>
                {upt}
              </ListItem>
            ))}

            {/* <Divider />
            <ListItem button onClick={handleClose_choose_upt_dialog}>
              her2
            </ListItem> */}
          </List>
        </Dialog>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Rst.Платы
        </MenuItem>

        <MenuItem>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Rst.Системы
        </MenuItem>

        <MenuItem>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Запуск игры
        </MenuItem>
      </Popover>
    </>
  );
}
