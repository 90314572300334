import React from 'react'
import socketio from "socket.io-client";

// export const socket = socketio.connect('http://80.78.245.36:5001', {'path':'/candysocket'});//candysocket 'transports': ['websocket']

//export const socket = socketio.connect('https://xn--80aaafswh5dfio6a.xn--p1ai', {'path':'/candysocket' });//:5001 candysocket asdasd'transports': ['websocket']
//export const socket = socketio.connect('http://80.78.245.36:5001', {'path':'/candysocket' });//:5001 candysocket 'transports': ['websocket']

export const socket = socketio.connect('https://xn--80aaafswh5dfio6a.xn--p1ai', {'path':'/candysocket/socket.io'});//candysocket

// export const socket = socketio.connect('http://localhost:5001', {'path':'/candysocket/socket.io'});//candysocket

export var myTimer = setInterval(function(){console.log("her")}, 1000);
export var myAuthTimer = setInterval(function(){}, 1000);

export const SocketContext = React.createContext();