import { faker } from "@faker-js/faker";
import { sample } from "lodash";

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  // avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.firstName(),
  company: faker.address.streetAddress(),
  isVerified: faker.datatype.boolean(),
  status: sample(["online", "offline"]),
  ercode: sample(["00", "01", "02", "03", "04", "05", "10", "12", "13", "15"]),
  // toys: `${1000} / ${faker.datatype.number({
  //   min: 10,
  //   max: 1000,
  // })}`,
  stoday: `
    ${faker.datatype.number({ min: 1, max: 50 })} / 
    ${faker.datatype.number({ min: 10, max: 100 })} / 
    ${faker.datatype.number({ min: 100, max: 300 })}
  `,
  sy24: `
    ${faker.datatype.number({ min: 1, max: 50 })} / 
    ${faker.datatype.number({ min: 10, max: 100 })} / 
    ${faker.datatype.number({ min: 100, max: 300 })}
  `,
  sy48: `
    ${faker.datatype.number({ min: 1, max: 50 })} / 
    ${faker.datatype.number({ min: 10, max: 100 })} / 
    ${faker.datatype.number({ min: 100, max: 300 })}
  `,

}));

export default users;
