import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import { SocketContext, socket, myTimer, myAuthTimer } from './main/mysocket';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <SocketContext.Provider value={{ '_socket': socket, '_timer': myTimer, '_auth_timer': myAuthTimer }}>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </SocketContext.Provider>
  );
}
