import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Button } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {

  const navigate = useNavigate();

  const supera_render = (item) => {

    let _email = localStorage.getItem('device_email');

    if(item.title !== 'Прошивки') {
      return <NavItem key={item.title} item={item} />;
    } else {
      if (_email === 'supera'){
        return <NavItem key={item.title} item={item} />;
      } else {
        return null;
      }
    }
    
 }

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          supera_render(item)
        ))}

        <Button
          sx={{mt: 5, p: 1, width: '100%'}}
          variant="contained"
          onClick={ () => {
            localStorage.setItem('device_id', 'exit her her');
            navigate('/login');
          } }
        >
          Exit
        </Button>

      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
