import { faker } from "@faker-js/faker";
import { sample } from "lodash";

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  // avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.firstName(),
  status: sample(["online", "offline"]),
  ercode: sample(["00", "01", "02", "03", "04", "05", "10", "12", "13", "15"]),
  address: faker.address.streetAddress(),
  colvo: `${1000} / ${faker.datatype.number({
    min: 10,
    max: 1000,
  })}`,

}));

export default users;
