// component
import SvgColor from '../../../components/svg-color';

import {
  Button,
} from "@mui/material";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Точки',
    path: '/dashboard/cps',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Настройка точки',
    path: '/dashboard/settings',
    icon: icon('ic_lock'),
  },
  {
    title: 'Транзакции',
    path: '/dashboard/trs',
    icon: icon('ic_cart'),
  },
  {
    title: 'Пользователи',
    path: '/dashboard/users',
    icon: icon('ic_user'),
  },
  {
    title: 'Прошивки',
    path: '/dashboard/updates',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'Выдачи',
  //   path: '/dashboard/gmsts',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Прошивки',
  //   path: '/dashboard/updates',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
