import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

//id, _mynumber, _cpid, _date, _money, _type, avatarUrl, isVerified


const transactions = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  // avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  _mynumber: index+1,
  _cpid: faker.name.firstName(),
  _date: new Date(faker.date.past(2)).toISOString().slice(0, 19).replace('T', ' / '),
  _money: faker.finance.amount(1, 100, 2),
  _type: sample(['мон.', 'куп.', 'тер.']),
  isVerified: faker.datatype.boolean(),
  status: sample(['online', 'offline']),
  role: sample([
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '10',
    '12',
    '13',
    '15',
  ]),
}));

export default transactions;
