import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";

import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";

import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";

import USERLIST from "../_mock/settings";

import { filter } from "lodash";
import { sentenceCase } from "change-case";

// components
import Label from "../components/label";
import Scrollbar from "../components/scrollbar";

import { useNavigate } from "react-router-dom";

import { useEffect, useState, useContext, useCallback, useRef } from "react";
import { forwardRef, ReactElement, Ref } from "react";

import { SocketContext } from "../main/mysocket";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "name", label: "Имя точки", alignRight: false },
  { id: "status", label: "Статус", alignRight: false },
  { id: "short_id", label: "Номер", alignRight: false },
  { id: "ercode", label: "Ошибки", alignRight: false },
  { id: "address", label: "Адрес", alignRight: false },
  { id: "colvo", label: "Товар (Общ. / Тек.)", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DashboardAppSetPage() {
  const _context = useContext(SocketContext);
  const socket = _context._socket;

  const navigate = useNavigate();

  const [cps_info, set_cps_info] = useState([]);
  const reftChargeCoordinates = useRef(null);
  reftChargeCoordinates.current = cps_info;

  const [cur_cpid, set_cur_cpid] = useState("");
  const refcur_cpid = useRef(null);
  refcur_cpid.current = cur_cpid;

  const theme = useTheme();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open_set_toys_dialog, set_open_set_toys_dialog] = useState(false);
  const [title_cpid_toys_dialog, set_title_cpid_toys_dialog] = useState("");

  const [nums_for_toys, set_nums_for_toys] = useState(1000);

  const [nums_for_set_cost_terminal, set_nums_for_set_cost_terminal] =
    useState(1);
  const [cost_for_set_cost_terminal, set_cost_for_set_cost_terminal] =
    useState(1000);
  const [
    title_cpid_set_cost_terminal_dialog,
    set_title_cpid_set_cost_terminal_dialog,
  ] = useState("");
  const [open_set_cost_terminal_dialog, set_open_set_cost_terminal_dialog] =
    useState(false);

  const [open_add_cpid_dialog, set_open_add_cpid_dialog] = useState(false);
  const [try_new_cpid, set_try_new_cpid] = useState("GRBDEVICE-999999999");
  const [try_new_name, set_try_new_name] = useState("Patricia Fernandez");
  const [try_new_address, set_try_new_address] = useState(
    "805 Jenna Spur Apt. 805 Brandonfurt, WI 38604"
  );

  const [open_set_delete_dialog, set_open_set_delete_dialog] = useState(false);

  const handleOpenMenu = (event, _cpid) => {
    console.log("handleOpenMenu ", _cpid);
    set_cur_cpid(_cpid);
    // refcur_cpid.current = _cpid;
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleSendCommand = (cmd) => {
    console.log("handleSendCommand ", cmd, refcur_cpid.current);
    setOpen(null);
    socket.emit(cmd, { cpid: refcur_cpid.current });
  };

  const handleOpenToysDialog = () => {
    set_nums_for_toys(1000);
    setOpen(null);
    set_title_cpid_toys_dialog(refcur_cpid.current);
    set_open_set_toys_dialog(true);
  };

  const handleOpenSetTerminalCostDialog = () => {
    set_nums_for_set_cost_terminal(1);
    setOpen(null);
    set_title_cpid_set_cost_terminal_dialog(refcur_cpid.current);
    set_open_set_cost_terminal_dialog(true);
  };

  const handleCloseToysDialog = () => {
    set_open_set_toys_dialog(false);
  };

  const handleCloseSetCostTerminalDialog = () => {
    set_open_set_cost_terminal_dialog(false);
  };

  const handleAddCpidDialog = () => {
    setOpen(null);
    set_open_add_cpid_dialog(true);
  };

  const handleCloseAddCpidDialog = () => {
    set_open_add_cpid_dialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpen(null);
    set_title_cpid_toys_dialog(refcur_cpid.current);
    set_open_set_delete_dialog(true);
  };

  const handleCloseDeleteDialog = () => {
    set_open_set_delete_dialog(false);
  };

  const handleAddCpidSendCommand = () => {
    console.log("handleAddCpidSendCommand ", try_new_cpid);
    handleCloseAddCpidDialog();

    let _device_email = localStorage.getItem("device_email");

    socket.emit("front_add_cpid_req", {
      email: _device_email,
      new_cpid: try_new_cpid,
      new_name: try_new_name,
      new_address: try_new_address,
    });
  };

  const handleAddCpidChange = (event) => {
    set_try_new_cpid(event.target.value);
    console.log(try_new_cpid);
  };
  const handleAddNameChange = (event) => {
    set_try_new_name(event.target.value);
    console.log(try_new_name);
  };
  const handleAddAddressChange = (event) => {
    set_try_new_address(event.target.value);
    console.log(try_new_address);
  };

  const handleSetToysSendCommand = () => {
    console.log("handleSendCommand ", nums_for_toys, refcur_cpid.current);
    handleCloseToysDialog();

    socket.emit("front_set_toys_req", {
      cpid: refcur_cpid.current,
      numtoys: nums_for_toys,
    });
  };

  const handleSetCostTerminalSendCommand = () => {
    console.log(
      "handleSendCommand ",
      nums_for_set_cost_terminal,
      cost_for_set_cost_terminal,
      refcur_cpid.current
    );
    handleCloseSetCostTerminalDialog();

    socket.emit("front_set_cost_termonal_req", {
      cpid: refcur_cpid.current,
      numtoys: nums_for_set_cost_terminal,
      costtoys: cost_for_set_cost_terminal,
    });
  };

  const handleSetDeleteSendCommand = () => {
    console.log("handleSetDeleteSendCommand ", refcur_cpid.current);
    handleCloseDeleteDialog();

    let _device_email = localStorage.getItem("device_email");

    socket.emit("front_delete_cpid_req", {
      email: _device_email,
      cpid: refcur_cpid.current,
    });
  };

  const handleNumsToysChange = (event) => {
    set_nums_for_toys(event.target.value);
    console.log(nums_for_toys);
  };

  const handleNumsSetCostTerminalChange = (event) => {
    set_nums_for_set_cost_terminal(event.target.value);
    console.log(nums_for_set_cost_terminal);
  };
  const handleCostSetCostTerminalChange = (event) => {
    set_cost_for_set_cost_terminal(event.target.value);
    console.log(cost_for_set_cost_terminal);
  };

  const handle_front_check_cps_res = useCallback((msg) => {
    if (msg) {
      // console.log("msg handle_front_check_cps_res", msg);

      let _t_user_w_cps = [];
      msg.map((user_info) => {
        let _t_cps_info = [];
        user_info.cps.map((cp_info) => {
          _t_cps_info.push({
            id: cp_info.cp_id,
            wifinumber: cp_info.wifinumber,
            myName: cp_info.name,
            myIsonline: cp_info.isOnline ? "online" : "offline",
            myShortId: cp_info.myShortId,
            myError: cp_info.error_code,
            myAddress: cp_info.address,
            myNtoys: cp_info.ntoys.join(" / "),
          });
        });

        _t_user_w_cps.push({
          email: user_info.email,
          cps: _t_cps_info,
        });
      });

      // let _t_cps_info = [];
      // msg.map((cp_info) => {
      //   _t_cps_info.push({
      //     id: cp_info.cp_id,
      //     wifinumber: cp_info.wifinumber,
      //     myName: cp_info.name,
      //     myIsonline: cp_info.isOnline ? "online" : "offline",
      //     myShortId: cp_info.myShortId,
      //     myError: cp_info.error_code,
      //     myAddress: cp_info.address,
      //     myNtoys: cp_info.ntoys.join(" / "),
      //   });
      // });

      if (
        JSON.stringify(reftChargeCoordinates.current) ===
        JSON.stringify(_t_user_w_cps)
      ) {
        console.log("tChargeCoordinates) === JSON.stringify(msg");
      } else {
        set_cps_info(_t_user_w_cps);
      }
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    socket.removeAllListeners();
    clearInterval(_context._auth_timer);
    clearInterval(_context._timer);

    socket.on("front_check_cps_res", handle_front_check_cps_res);

    let _device_email = localStorage.getItem("device_email");
    let _deviceId = localStorage.getItem("device_id");

    clearInterval(_context._timer);
    _context._timer = setInterval(function () {
      socket.emit("front_check_cps_req", {
        email: _device_email,
        device_id: _deviceId,
      });
    }, 1000);

    // return () => {};
  }, [socket]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        {/* <Grid container spacing={3} sx={{ mb: 5 }}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Weekly Sales" total={714000} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="New Users" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Item Orders" total={1723315} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid>

        </Grid> */}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom></Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleAddCpidDialog()}
          >
            Добавить точку
          </Button>
        </Stack>

        {cps_info.map((usid) => {
          return (
            <Card sx={{ marginBottom: 4 }}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={cps_info.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      myuserid={usid.email}
                    />
                    <TableBody>
                      {usid.cps.map((row) => {
                        const {
                          id,
                          wifinumber,
                          myName,
                          myIsonline,
                          myShortId,
                          myError,
                          myAddress,
                          myNtoys,
                        } = row;
                        const selectedUser = selected.indexOf(myName) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUser}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedUser}
                                onChange={(event) => handleClick(event, myName)}
                              />
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="column"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {id}
                                </Typography>
                              </Stack>

                              {wifinumber ? (
                                <Label color={"warning"}>{wifinumber}</Label>
                              ) : (
                                ""
                              )}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                {/* <Typography variant="subtitle2" noWrap>
                                {myName}
                              </Typography> */}

                                <Label color={"primary"}>{myName}</Label>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">
                              <Label
                                color={
                                  (myIsonline === "offline" && "error") ||
                                  "success"
                                }
                              >
                                {sentenceCase(myIsonline)}
                              </Label>
                            </TableCell>

                            <TableCell align="left">{myShortId}</TableCell>

                            <TableCell align="left">{myError}</TableCell>

                            <TableCell align="left">{myAddress}</TableCell>

                            <TableCell align="left">{myNtoys}</TableCell>

                            {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, id)}
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete
                                words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={cps_info.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </Card>
          );
        })}

        
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleSendCommand("front_reset_esp_req")}>
          {" "}
          {/*onClick={ this.handleChangeDash } */}
          <Iconify icon={"eva:alert-circle-outline"} sx={{ mr: 2 }} />
          Rst.Платы
        </MenuItem>

        <MenuItem onClick={() => handleSendCommand("front_reset_as_req")}>
          <Iconify icon={"eva:alert-triangle-outline"} sx={{ mr: 2 }} />
          Rst.Системы
        </MenuItem>

        <MenuItem onClick={() => handleSendCommand("front_rungame_req")}>
          <Iconify icon={"eva:arrow-right-outline"} sx={{ mr: 2 }} />
          Запуск игры
        </MenuItem>

        <MenuItem onClick={() => handleOpenToysDialog()}>
          <Iconify icon={"eva:sync-outline"} sx={{ mr: 2 }} />
          Обновить товар
        </MenuItem>

        <MenuItem onClick={() => handleOpenDeleteDialog()}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Удалить
        </MenuItem>

        <MenuItem onClick={() => handleOpenSetTerminalCostDialog()}>
          <Iconify icon={"eva:sync-outline"} sx={{ mr: 2 }} />
          Уст. цену Тер.
        </MenuItem>
      </Popover>

      <Dialog
        fullScreen
        open={open_set_toys_dialog}
        onClose={handleCloseToysDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseToysDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Обновить товар для {title_cpid_toys_dialog}
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack
          sx={{
            m: 2,
            height: "100%",
          }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          {/* <Avatar alt={name} src={avatarUrl} /> */}
          {/* <Typography variant="subtitle2" noWrap>
                                {myName}
                              </Typography> */}

          <TextField
            id="outlined-number"
            label="Количество товара"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={1000}
            color="secondary"
            focused
            onChange={handleNumsToysChange}
          />
          <Button
            edge="start"
            color="primary"
            onClick={handleSetToysSendCommand}
            aria-label="closebutton"
            variant="outlined"
          >
            Отправить
          </Button>
        </Stack>
      </Dialog>

      <Dialog
        fullScreen
        open={open_add_cpid_dialog}
        onClose={handleCloseAddCpidDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseAddCpidDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Ввод новой точки
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack
          sx={{
            m: 2,
            height: "100%",
          }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <TextField
            sx={{
              width: "30%",
            }}
            id="outlined-number"
            label="Введите Id точки"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={"GRBDEVICE-99999999"}
            color="secondary"
            focused
            onChange={handleAddCpidChange}
          />
          <TextField
            sx={{
              width: "30%",
            }}
            id="outlined-number"
            label="Введите ИМЯ точки"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={"Patricia Fernandez"}
            color="secondary"
            focused
            onChange={handleAddNameChange}
          />
          <TextField
            sx={{
              width: "30%",
            }}
            id="outlined-number"
            label="Введите АДРЕС точки"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={"805 Jenna Spur Apt. 805 Brandonfurt, WI 38604"}
            color="secondary"
            focused
            onChange={handleAddAddressChange}
          />
          <Button
            edge="start"
            color="primary"
            onClick={handleAddCpidSendCommand}
            aria-label="closebutton"
            variant="outlined"
          >
            Подтвердить
          </Button>
        </Stack>
      </Dialog>

      <Dialog
        fullScreen
        open={open_set_delete_dialog}
        onClose={handleCloseDeleteDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDeleteDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Вы действительно хотите удалить точку {title_cpid_toys_dialog} ?
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack
          sx={{
            m: 2,
            height: "100%",
          }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Button
            sx={{
              width: "30%",
            }}
            edge="start"
            color="error"
            onClick={handleSetDeleteSendCommand}
            aria-label="closebutton"
            variant="outlined"
          >
            Подтвердить
          </Button>
        </Stack>
      </Dialog>

      <Dialog
        fullScreen
        open={open_set_cost_terminal_dialog}
        onClose={handleCloseSetCostTerminalDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseSetCostTerminalDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Установить цену для {title_cpid_set_cost_terminal_dialog}
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack
          sx={{
            m: 2,
            height: "100%",
          }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          {/* <Avatar alt={name} src={avatarUrl} /> */}
          {/* <Typography variant="subtitle2" noWrap>
                                {myName}
                              </Typography> */}

          <TextField
            id="outlined-number"
            label="Количество товара"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={1}
            color="secondary"
            focused
            onChange={handleNumsSetCostTerminalChange}
          />

          <TextField
            id="outlined-number"
            label="Цена за все товары в копейках!!!"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={1000}
            color="secondary"
            focused
            onChange={handleCostSetCostTerminalChange}
          />
          <Button
            edge="start"
            color="primary"
            onClick={handleSetCostTerminalSendCommand}
            aria-label="closebutton"
            variant="outlined"
          >
            Отправить
          </Button>
        </Stack>
      </Dialog>
    </>
  );
}
