// ----------------------------------------------------------------------

let _device_email = localStorage.getItem("device_email");

const account = {
  displayName: _device_email,
  email: _device_email,
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
