// import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useEffect, useState, useContext, useCallback } from "react";

// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";

import { SocketContext } from "../../../main/mysocket";

// ----------------------------------------------------------------------

export default function RegForm() {
  const _context = useContext(SocketContext);
  const socket = _context._socket;

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loginButtonIsDisabled, set_loginButtonIsDisabled] = useState(false);

  const [_email, set_email] = useState("");
  const [_passw, set_passw] = useState("");
  const [_phone, set_phone] = useState("");

  const handleClick = () => {
    // navigate('/dashboard', { replace: true });

    console.log("handleClick ", _email, _phone, _passw);
    socket.emit("front_signup_user", { email: _email, phone: _phone, pass: _passw });

    set_loginButtonIsDisabled(true);

    setTimeout(function () {
      set_loginButtonIsDisabled(false);

      navigate("/login", { replace: true });
    }, 3000);
  };

  const handleEmailChange = (event) => {
    set_email(event.target.value);
    console.log(_email);
  };

  const handlePasswordChange = (event) => {
    set_passw(event.target.value);
    console.log(_passw);
  };

  const handlePhoneChange = (event) => {
    set_phone(event.target.value);
    console.log(_phone);
  };

  const handle_front_check_user_res = useCallback((msg) => {
    if (msg) {
      console.log("msg handle_front_check_user_res", msg);

      // // localStorage.setItem('device_id', msg.device_id)
      // if (msg.status) {
      //   navigate("/dashboard", { replace: true });
      // }
    }
  }, []);

  // const handle_front_check_login_status = useCallback((msg) => {
  //   if (msg) {
  //     console.log("msg handle_front_check_login_status", msg);

  //     if (msg.error === "") {
  //       localStorage.setItem("device_id", msg.device_id);
  //       socket.emit("front_check_user", {});
  //     }
  //   }
  // }, []);

  useEffect(() => {
    socket.removeAllListeners();
    clearInterval(_context._auth_timer);
    clearInterval(_context._timer);

    socket.on("front_check_reg_status", handle_front_check_user_res);
    // socket.on("front_check_login_status", handle_front_check_login_status);

    // socket.emit("front_check_user", {});

    // socket.on("check_deviceId_res", handle_check_device_message);

    // return () => {};
  }, [socket]);

  return (
    <>
      <Stack spacing={3} sx={{ my: 4 }}>
        <TextField
          name="email"
          label="Email address"
          onChange={handleEmailChange}
        />

        <TextField
          name="phone"
          label="Phone number"
          onChange={handlePhoneChange}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handlePasswordChange}
        />
      </Stack>

      <LoadingButton
        disabled={loginButtonIsDisabled}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
      >
        Sign up
      </LoadingButton>
    </>
  );
}
