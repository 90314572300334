// import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useEffect, useState, useContext, useCallback } from "react";

// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";

import { SocketContext } from "../../../main/mysocket";

import { v4 as uuid } from "uuid";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const _context = useContext(SocketContext);
  const socket = _context._socket;

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loginButtonIsDisabled, set_loginButtonIsDisabled] = useState(false);

  const [_email, set_email] = useState("");
  const [_passw, set_passw] = useState("");

  const handleClick = () => {
    // navigate('/dashboard', { replace: true });

    console.log("handleClick ", _email, _passw);

    let _deviceId = uuid();

    localStorage.setItem('device_id', _deviceId);
    localStorage.setItem('device_email', _email);

    socket.emit("front_login_user", {'email': _email, 'pass': _passw, 'device_id': _deviceId});

    set_loginButtonIsDisabled(true);

    setTimeout(function () {
      set_loginButtonIsDisabled(false);
    }, 3000);
  };

  const handleEmailChange = (event) => {
    set_email(event.target.value);
    console.log(_email);
  };

  const handlePasswordChange = (event) => {
    set_passw(event.target.value);
    console.log(_passw);
  };

  const handle_front_check_user_res = useCallback((msg) => {
    if (msg) {
      console.log("msg handle_front_check_user_res", msg);

      // localStorage.setItem('device_id', msg.device_id)
      // let _deviceId = localStorage.getItem("device_id");
      // console.log('_deviceId ', _deviceId)

      if(msg.status){
        navigate('/dashboard', { replace: true });
      }
    }
  }, []);

  const handle_front_check_login_status = useCallback((msg) => {
    if (msg) {

      console.log("msg handle_front_check_login_status", msg);

      if(msg.error === ''){
        localStorage.setItem('device_id', msg.device_id);
        socket.emit("front_check_user", {device_id: msg.device_id});
      }
    }
  }, []);

  useEffect(() => {

    socket.removeAllListeners();
    clearInterval(_context._auth_timer);
    clearInterval(_context._timer);

    socket.on("front_check_user_res", handle_front_check_user_res);
    socket.on("front_check_login_status", handle_front_check_login_status);

    socket.emit("front_check_user", {device_id: 'herher'});

    // socket.on("check_deviceId_res", handle_check_device_message);

    // return () => {};
  }, [socket]);

  return (
    <>
      <Stack spacing={3} sx={{ my: 4 }}>
        <TextField
          name="email"
          label="Email address"
          onChange={handleEmailChange}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handlePasswordChange}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton
        disabled={loginButtonIsDisabled}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
      >
        Login
      </LoadingButton>
    </>
  );
}
